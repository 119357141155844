/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import '../stylesheets/application.scss'
const images = require.context('../images', true)

import Vue from 'vue/dist/vue.esm'

import store from '../stores'
import Goll from '../goll.vue'


import VuePlyr from 'vue-plyr'
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false }
  },
  emit: [ 'ended' ]
})

import "vue-plyr/dist/vue-plyr.css"

Vue.component('goll', Goll)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    store,
    el: '[data-behavior="vue"]',
  })
})
