<template>
  <div
    v-if="showing"
    class="z-10 fixed inset-0 w-full h-screen flex items-contern justify-center bg-smi-75"
  >
    <button
      aria-label="close"
      class="z-10 px-5 py-2 bg-white absolute top-0 right-0 text-xl text-gray-500 my-2 mx-4"
      @click.prevent="close"
    >
      <h1 class="text-5xl text-black font-bold">&times;</h1>
    </button>
    <div class="w-full object-cover bg-white shadow-lg p-8">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style>
</style>