<template>
  <div>
    <div v-if="false">{{ videos }}</div>
    <transition-group
      v-if="videos && videos.length > 0"
      name="fade"
      tag="div"
      class="container flex flex-wrap gap-2"
    >
      <div
        v-for="video in videos"
        :key="video.id"
        class="box-border relative flex justify-between flex-col bg-white w-1/1 xs:w-1/1 sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/4 rounded overflow-hidden border hover:shadow-xl group"
      >
        <div class="px-2 py-1 flex relative h-20 items-center text-center bg-blue/20 w-full z-10 group-hover:bg-blue group-hover:text-white" style="box-shadow: 0px 1px 10px rgba(0,0,0,0.1);">
          <div class="flex-auto font-semibold text-xl">{{ video.title }}</div>
        </div>

        <div class="relative">
          <div class="absolute flex inset-0 justify-center items-center object-center">
            <button @click="select_video(video.id)" class="text-blue/75 bg-light-500 rounded-xl p-3 hover:text-white hover:bg-blue">
              <svg class="w-10 h-10 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/>
              </svg>
            </button>
          </div>
          <img
            v-if="video && video.picture"
            @click="select_video(video.id)"
            class="w-full object-cover mx-auto"
            style="cursor: pointer;"
            :src="video.picture.medium"
            :alt="video.title"
          />
          <img
            v-else
            @click="select_video(video.id)"
            class="w-full object-cover mx-auto"
            style="cursor: pointer;"
            src="https://via.placeholder.com/350x160"
            alt="Placeholder"
          />
        </div>

        <div class="flex flex-wrap px-6 py-4 text-center items-center">
          <p class="text-gray-700 text-base mb-2">{{ video.teaser }}</p>
          <div class="flex flex-wrap justify-center w-full gap-1">
            <span
              v-for="tag in video.tags"
              @click="select_tag(tag.id)"
              :key="tag.id"
              class="cursor-pointer rounded-xl px-3 py-2 text-xs text-white group-hover:bg-blue"
              :class="{
                'bg-blue/50 hover:!bg-black': !$store.getters.active_tag(tag.id),
                'bg-black': $store.getters.active_tag(tag.id)
              }"
            >{{tag.title}}</span>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  methods: {
    select_video(video) {
      this.$store.dispatch('select_video', video)
    },
    select_tag (tag_id) {
      this.$store.dispatch('toggle_tag', tag_id)
    }
  },
  computed: {
    videos: function() { return this.$store.getters.selected_videos }
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1) ease-in; */
  transform: translateX(10px);
}
.fade-enter,
.slide-fade-leave-to {
  transition: all 0.3s ease;
  /* transform: translateX(10px); */
  opacity: 0;
}
</style>