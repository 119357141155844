<template>
  <div class="container my-4 justify-center content-center">
    <h2
      class="text-center font-light mb-2"
    >Wählen Sie bei Bedarf einen oder mehrere Tags um die Auswahl zu filtern</h2>
    <div class="flex flex-wrap justify-center gap-2">
      <button
        v-for="tag in tags"
        :key="tag.id"
        @click="select_tag(tag.id)"
        class="rounded-2xl border border-dark-blue hover:bg-dark-blue py-2 px-4 font-semibold hover:text-white"
        :class="{
          'bg-dark-blue text-white': $store.getters.active_tag(tag.id)
        }"
      >
        {{ tag.title }}
        <span v-if="$store.getters.active_tag(tag.id)">&times;</span>
      </button>
    </div>
    <pre v-if="false">
    {{ tags }}
    </pre>
  </div>
</template>

<script>
export default {
  data() { return {}},
  methods: {
    select_tag (tag_id) {
      this.$store.dispatch('toggle_tag', tag_id)
    }
  },
  computed: {
    tags: function() { return this.$store.state.tags },
    active_tags: function() { return this.$store.state.active_tags }
  }
}
</script>

<style>
</style>