<template>
  <div class="container mx-auto bg-dark-blue text-white p-10 text-xl border mb-4 rounded-lg">
    <span class="font-semibold">Goll Video.</span> Wir liefern Ihnen diverse kurze Videos zu unteren Produkten, Montage und Produktion sowie
    Anwendungsbeispiele usw.
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
