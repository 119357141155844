<template>
  <div id="app" class="mb-10">
    <header-navigation :search_visible="false" @search="search"></header-navigation>
    <banner v-if="false"></banner>
    <video-box></video-box>
    <div class="container mx-auto">
      <tags></tags>
      <video-index class="pt-4"></video-index>
    </div>
  </div>
</template>

<script>

import HeaderNavigation from './components/header'
import Banner from './components/banner'
import Tags from './components/tags'
import VideoBox from './components/video-box'
import VideoIndex from './components/video-index'

export default {
  data: function () {
    return {
    }
  },
  methods: {
    search( v ) {
      // console.log( v )
    }
  },
  async mounted () {
    await this.$store.dispatch( 'fetchStartup' )
  },
  components: {
    HeaderNavigation,
    Banner,
    Tags,
    VideoIndex,
    VideoBox
  }
}
</script>
