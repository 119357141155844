<template>
  <header class="bg-transparent ml-3 mt-3 lg:mb-10">
    <div class="flex sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3">
      <div class="justify-between px-4 py-3 sm:p-0 mr-10">
        <img src="~images/anuba.logo.svg" class="mr-10 h-10 max-h-50 inline-block" />
        <img src="~images/autera.claim.logo.svg" class="mr-10 h-10 max-h-50 inline-block" />
        <img src="~images/goll.logo.svg" class="h-10 max-h-50 inline-block" />
      </div>
      <div v-if="search_visible" class="flex-auto object-left relative">
        <input
          v-model="search"
          @keyup="$emit('search', search)"
          type="search"
          class="px-3 py-2 pl-10 text-xl shadow focus:outline-none"
          placeholder="suchen ..."
        />
        <div class="absolute pl-3" style="top: 14px;">
          <svg
            style="height: 18px; width: 18px; display: block; fill: currentcolor;"
            viewBox="0 0 16 16"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          >
            <path
              d="m2.5 7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5m13.1 6.9-2.8-2.9c.7-1.1 1.2-2.5 1.2-4 0-3.9-3.1-7-7-7s-7 3.1-7 7 3.1 7 7 7c1.5 0 2.9-.5 4-1.2l2.9 2.8c.2.3.5.4.9.4.3 0 .6-.1.8-.4.5-.5.5-1.2 0-1.7"
            />
          </svg>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: [ 'search_visible' ],
  data() {
    return {
      search: ''
    }
  }
}
</script>

<style>
</style>