<template>
  <modal :showing="is_video_selected && video"
         @close="close_video"
         class="z-20">

    <vue-plyr v-if="this.showing && video">
      <div v-if="video.url.indexOf('youtu.be') >= 0"
        data-plyr-provider="youtube"
        data-plyr-
        :data-plyr-embed-id="video.url.match(/(\.be\/)+([^\/]+)/)[2]"
      ></div>

      <video v-else-if="video.url.indexOf('youtu.be') == -1 && video.picture"
        :poster="video.picture.large"
        :src="video.url"
        autoplay="true"
        fullscreen="true"
        loop="true"
      >
        <source :src="video.url" type="video/mp4" />
      </video>

      <video v-else-if="video.url.indexOf('youtu.be') == -1"
        poster="https://via.placeholder.com/800x800"
        :src="video.url"
        autoplay="true"
        fullscreen="true"
        loop="true"
        >
        <source :src="video.url" type="video/mp4" />
      </video>
    </vue-plyr>
  </modal>
</template>

<script>
import Modal from './modal'

export default {
  data() {
    return {
      showing: false
    }
  },
  methods: {
    close_video() {
      this.$store.dispatch('select_video', null)
    }
  },
  computed: {
    is_video_selected: function()  {
      this.showing = this.$store.state.selected_video_id != null
      return this.showing
    },
    video: function() { return this.$store.getters.selected_video },
    videos: function() { return this.$store.getters.videos }
  },
  components: {
    Modal
  }
}
</script>
